import React from 'react';
import clsx from 'clsx';
import { useStyles } from '../styles';
import PropTypes from 'prop-types';
import t from '@unicat/i18n/Translate';
import { ReactComponent as Redirect } from '@assets/icons/redirect_24_px.svg';
import StyledLinkButton from '@unicat/UI/StyledLinkButton';
import { linkButtonColorSchemas } from '@styles/styles';

const FillForm = () => {
  const classes = useStyles();

  const linkButtonColors = linkButtonColorSchemas();
  return (
    <>
      {/** title */}
      <div className={classes.contactsInner}>
        <p className={clsx([classes.contactsTitle])}>{t('We are looking forward to your great wishes and ideas!')}</p>
        {/** paragraph */}
        <div className={clsx([classes.fillFormParagraph])}>
          {t('To make an order, please,')}
          <br />
          {t('fill the Google form')}
        </div>
        {/** button */}
        <StyledLinkButton className={linkButtonColors.yellow}>
          <p>
            {t('Fill the Google Form')}&nbsp;
            <Redirect />
          </p>
        </StyledLinkButton>
        <div className={classes.fillFormLinkWrapper}>{/** DONE: change link depends on selected language. */}</div>
        <div className={classes.fillFormLinkWrapper}>{/** DONE: change link depends on selected language. */}</div>
      </div>
    </>
  );
};

FillForm.defaultProps = {};

FillForm.propTypes = {
  config: PropTypes.shape({
    DefaultLanguage: PropTypes.string,
    phones: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.shape({
      title: PropTypes.string,
      geo: PropTypes.string,
      link: PropTypes.string,
      mobileDeeplink: PropTypes.string,
      mobileNavigate: PropTypes.string,
      browserMap: PropTypes.string,
      browserNavigate: PropTypes.string,
    }),
    socialLinks: PropTypes.shape({
      fb: PropTypes.string,
      instagram: PropTypes.string,
      linkedin: PropTypes.string,
      messenger: PropTypes.string,
      twitter: PropTypes.string,
      youtube: PropTypes.string,
    }),
    forms: PropTypes.shape({
      en: PropTypes.string,
      ru: PropTypes.string,
      ua: PropTypes.string,
      sv: PropTypes.string,
    }),
  }),
};

export default FillForm;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useStyles } from '../styles';
import t from '@unicat/i18n/Translate';
import { ReactComponent as Mail } from '@assets/icons/mail_32_px.svg';

const BottomCopyright = ({ config }) => {
  const classes = useStyles();
  const { email } = config;

  return (
    <>
      <div className={classes.bottomWaveInner}>
        <div className={clsx([classes.contactsInfoBlock, classes.mb1])}>
          {/** title */}
          <p className={clsx([classes.bottomTitle])}>{t('We are open for cooperation')}</p>
          {/** email */}
          <div className={clsx([classes.contactsInfoBlock, classes.bottomEmail])}>
            <div className={classes.contactsInfoLogo}>
              <Mail />
            </div>
            <div className={classes.contactsInfoInfo}>
              {email.map((item) => (
                <a className={''} href={`mailto:${item}`} key={item}>
                  {item}
                </a>
              ))}
            </div>
          </div>
        </div>
        {/** copyright */}
        <div className={clsx([classes.contactsInfoBlock, classes.mb1])}>
          <p className={classes.bottomRights}>{t('Unicat™ 2019-2020, All Rights Reserved')}</p>
        </div>
      </div>
    </>
  );
};

BottomCopyright.defaultProps = {};

BottomCopyright.propTypes = {
  config: PropTypes.shape({
    DefaultLanguage: PropTypes.string,
    phones: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.shape({
      title: PropTypes.string,
      geo: PropTypes.string,
      link: PropTypes.string,
      mobileDeeplink: PropTypes.string,
      mobileNavigate: PropTypes.string,
      browserMap: PropTypes.string,
      browserNavigate: PropTypes.string,
    }),
    socialLinks: PropTypes.shape({
      fb: PropTypes.string,
      instagram: PropTypes.string,
      linkedin: PropTypes.string,
      messenger: PropTypes.string,
      twitter: PropTypes.string,
      youtube: PropTypes.string,
    }),
    forms: PropTypes.shape({
      en: PropTypes.string,
      ru: PropTypes.string,
      ua: PropTypes.string,
      sv: PropTypes.string,
    }),
  }),
};

export default BottomCopyright;

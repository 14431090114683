import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Language from '@unicat/i18n/LanguageContext';
import { config } from '@config';
import { useStyles } from './styles';

const StyledLinkButton = ({ children, className = '' }) => {
  const language = useContext(Language);
  const href = config.forms[language.lang];
  const classes = useStyles();

  return (
    <a href={href} target={'_blank'} rel={'noopener noreferrer'} className={clsx([classes.fillFormLink, className])}>
      {children}
    </a>
  );
};

StyledLinkButton.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string.isRequired,
};

export default StyledLinkButton;

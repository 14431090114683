import React from 'react';
import clsx from 'clsx';
import ContactUs from './ContactUs';
import BottomCopyright from './BottomCopyright';
import FillForm from './FillForm';
import { useStyles } from './styles';
import { config } from '@config';
import { anchors } from '@config/headerRoutes';

const Footer = () => {
  const classes = useStyles();

  return (
    <footer id={anchors.Footer} className={classes.footer}>
      {/** info block */}
      <div className={classes.footerInner}>
        {/** left block */}
        <div className={classes.contacts}>
          <ContactUs config={config} />
        </div>
        {/** right block */}
        <div className={clsx([classes.fillForm, classes.backgroundPaw])}>
          <FillForm />
        </div>
      </div>
      {/** bottom */}
      <div className={classes.bottomWave}>
        <BottomCopyright config={config} />
      </div>
    </footer>
  );
};

Footer.defaultProps = {};
Footer.propTypes = {};

export default Footer;

import { makeStyles } from '@material-ui/core/styles';

export const linkButtonColorSchemas = makeStyles((theme) => ({
  purple: {
    ...theme.actionLinkButton,
    backgroundColor: theme.palette.purple,
    ...theme.typography.normalWhiteText /*titlesColor*/,
    ...theme.buttonOutline,
  },
  yellow: {
    ...theme.actionLinkButton,
    ...theme.typography.actionPurpleText,
    ...theme.buttonOutline,
  },
}));

import { makeStyles } from '@material-ui/core/styles';
import bgCopyrightSvg from '@backgrounds/Footer/bg_copyright.svg';
import pawBackground from '@backgrounds/Footer/bg_footer_paw.svg';
import pawSvg from '@backgrounds/Footer/bg_paw_v2.svg'; // 1356x655, 2.6kb

// 'xs' | 'sm' | 'md' | 'lg' | 'xl'

const backgroundPos = ({ high, width }) => `${high ?? 'bottom 50%'} ${width ?? 'right -30vh'}`;
const beforeAfter = {
  content: '""',
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: backgroundPos({}),
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 0,
};
const columnContainer = (theme) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    textAlign: 'left',
    padding: '0rem 1rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: 700,
      padding: '0rem 5rem',
      width: '100%',
      marginTop: 100,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 100,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: ' 430px',
      marginTop: 75,
    },
    '& h3': {
      textAlign: 'left',
      marginTop: 'unset',
    },
  };
};

export const useStyles = makeStyles((theme) => ({
  /** layouts, containers */
  footer: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: 'max(800px, 100vh)',
    justifyContent: 'center',
    backgroundColor: theme.palette.pinkBackgroundColor,
    color: theme.palette.grayTextColor,

    [theme.breakpoints.down('md')]: {
      padding: '50px 0 200px',
    },
    '& p': {
      margin: 0,
    },
    '& *': {
      zIndex: '1',
      transition: 'all 0.25s',
    },
    '&::before': {
      ...beforeAfter,
      backgroundImage: `url(${pawBackground})`,
      [theme.breakpoints.up('lg')]: {
        backgroundSize: 'auto 70%',
      },
      [theme.breakpoints.down('md')]: {
        backgroundPosition: backgroundPos({
          high: 'top 10%',
          width: 'right -40vw',
        }),
      },
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: backgroundPos({
          high: 'top 10%',
          width: 'left 10vw',
        }),
      },
    },
    '&::after': {
      ...beforeAfter,
      backgroundImage: `url(${pawSvg})`,
      [theme.breakpoints.up('lg')]: {
        backgroundSize: 'auto 70%',
      },
      [theme.breakpoints.down('md')]: {
        backgroundPosition: backgroundPos({
          high: 'top 10%',
          width: 'right -40vw',
        }),
      },
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: backgroundPos({
          high: 'top 10%',
          width: 'left 10vw',
        }),
      },
    },
  },
  /** backgrounds */
  backgroundPaw: {
    overflow: 'visible',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      order: -1,
    },
  },
  /** generics */
  mb1: {
    marginBottom: '1rem',
  },
  mb2: {
    marginBottom: '2rem',
  },
  footerInner: {
    display: 'flex',
    width: 'auto',
    height: 'max-content',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      // width: '100%',
    },
  },
  /** Contact Us */
  contacts: columnContainer(theme),
  contactsInner: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
      minWidth: '430px',
      alignSelf: 'center',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 'unset',
    },
  },
  contactsTitle: {
    ...theme.typography.h2PurpleText,
    paddingBottom: '1rem',
    maxWidth: '430px',
  },
  contactsInfoBlock: {
    display: 'flex',
    fontSize: '1rem',
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      // padding: '0rem 5rem',
      justifyContent: 'left',
    },
  },
  contactsInfoLogo: {
    flexShrink: 1,
    marginRight: 20,
    minWidth: '38px',
    display: 'flex',
    justifyContent: 'space-evenly',
    color: theme.palette.purpleTextColor,
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
    },
  },
  contactsInfoInfo: {
    ...theme.typography.normalGrayText,
    flexGrow: 3,
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '80%',

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
    },
  },
  contactsPhoneNumber: {
    display: 'block',
    fontSize: '1.5rem',
    fontWeight: 600,
    // do extra text boldness
    textShadow: `0 0 1px ${theme.palette.grayTextColor}`,
    marginBottom: '0.5rem',
  },
  /** Fill Form */
  fillForm: columnContainer(theme),
  fillFormParagraph: {
    ...theme.typography.normalGrayText,
  },
  fillFormLinkWrapper: {
    fontWeight: 'bold',
    paddingTop: '2rem',
  },
  /** Copyright */
  bottomWave: {
    width: '100%',
    backgroundImage: `url(${bgCopyrightSvg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '180px',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      '& p': {
        width: '100%',
        textAlign: 'left',
      },
    },
    [theme.breakpoints.up(1280)]: {
      height: 'min(180px, 22vh)',
      zIndex: 0,
    },
  },
  bottomWaveInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gridGap: '20%',
    padding: '0rem 5rem',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '0rem 0.8rem',
    },
  },
  bottomTitle: {
    ...theme.typography.titlePurpleText,
    paddingBottom: '0.8rem',
    // do extra text boldness
    textShadow: `0 0 1px ${theme.palette.darkPurpleTextColor}`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'left',
    },
  },
  bottomRights: {
    alignSelf: 'flex-end',
    fontSize: '0.7rem',
    [theme.breakpoints.down('sm')]: {
      '&p': {
        width: 'auto',
        textAlign: 'center !important',
      },
    },
  },
  bottomEmail: {
    width: 'auto',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      justifyContent: 'left',
      padding: '0 0',
    },
  },
}));

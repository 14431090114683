import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useStyles } from '../styles';
import t from '@unicat/i18n/Translate';
import SocialButtons from '@unicat/SocialButtons';
import { ReactComponent as Phone } from '@assets/icons/phone_32_px.svg';
import { ReactComponent as Mail } from '@assets/icons/mail_32_px.svg';
import { ReactComponent as Location } from '@assets/icons/location_32_px.svg';
import { anchors } from '@config/headerRoutes';

const ContactUs = ({ config }) => {
  const classes = useStyles();
  const { phones, email, location } = config;

  const multiline = (line) => line.split('\n').reduce((r, c, x) => (r ? [...r, <br key={x} />, c] : [c]), null);

  return (
    <>
      {/** titles */}
      <div className={classes.contactsInner}>
        <h3 id={anchors.ContactUs} className={clsx([classes.contactsTitle])}>
          {t('Contact us')}
        </h3>
        {/** phones */}
        <div className={clsx([classes.contactsInfoBlock, classes.mb2])}>
          <div className={classes.contactsInfoLogo}>
            <Phone />
          </div>
          <div className={classes.contactsInfoInfo}>
            {(phones || []).map((item) => (
              <a className={clsx([classes.big, classes.contactsPhoneNumber])} href={`tel:${item}`} key={item}>
                {item}
              </a>
            ))}
          </div>
        </div>
        {/** email */}
        <div className={clsx([classes.contactsInfoBlock, classes.mb2])}>
          <div className={classes.contactsInfoLogo}>
            <Mail />
          </div>
          <div className={classes.contactsInfoInfo}>
            {(email || []).map((item) => (
              <a className={''} href={`mailto:${item}`} key={item}>
                {item}
              </a>
            ))}
          </div>
        </div>
        {/** address */}
        <div className={clsx([classes.contactsInfoBlock, classes.mb2])}>
          <div className={classes.contactsInfoLogo}>
            <Location />
          </div>
          <div className={classes.contactsInfoInfo}>
            {location && (
              <a className={''} href={`${location.link}`} target={'_blank'} rel={'noopener noreferrer'}>
                {multiline(t(location.title))}
              </a>
            )}
          </div>
        </div>
        {/** social networks */}
        <SocialButtons />
      </div>
    </>
  );
};

ContactUs.defaultProps = {};

ContactUs.propTypes = {
  config: PropTypes.shape({
    DefaultLanguage: PropTypes.string,
    phones: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.shape({
      title: PropTypes.string,
      geo: PropTypes.string,
      link: PropTypes.string,
      mobileDeeplink: PropTypes.string,
      mobileNavigate: PropTypes.string,
      browserMap: PropTypes.string,
      browserNavigate: PropTypes.string,
    }),
    socialLinks: PropTypes.shape({
      fb: PropTypes.string,
      instagram: PropTypes.string,
      linkedin: PropTypes.string,
      messenger: PropTypes.string,
      twitter: PropTypes.string,
      youtube: PropTypes.string,
    }),
    forms: PropTypes.shape({
      en: PropTypes.string,
      ru: PropTypes.string,
      ua: PropTypes.string,
      sv: PropTypes.string,
    }),
  }),
};

export default ContactUs;
